/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { gql } from 'graphql-request';
import { request, query } from '@distancify/drapejs-litium';

export const commands = {
  refreshMedia: defineCommand<{
    url: string;
    filters: string;
    searchQuery: string;
    page: string;
  }>('refreshMedia'),
  login: defineCommand<{
    url: string;
    username: string;
    password: string;
  }>('login'),
  logout: defineCommand<{
    url: string;
  }>('logout'),
};

export const receivers = {
  refreshMedia: defineReceiver(
    commands.refreshMedia,
    async function (command, data) {
      const result = await request(this.cache, 
        query(gql`
        query getMedia($url: String!, $searchQuery: String, $filters: String, $page: Int) {
          session(url: $url) {
            mediaEntries (searchQuery: $searchQuery, filters: $filters, page: $page) {
              groups {
                groupTitle
                groupSubTitle
                model
                subGroups {
                  subGroupTitle
                  color
                  folders {
                    systemId
                    name
                    files {
                      systemId
                      name
                      fileType
                      extension
                    }
                  }          
                }
              }
              filters {
                field
                values {
                  value
                  displayValue
                  isSelected
                  hasNoResults
                }
              }
              page
              totalPages
            }
          }
        }
      `),
        {
          ...command,
        },
      );
      return result.session.mediaEntries;
    },
    'litium',
  ),
  login: defineReceiver(
    commands.login,
    async function (command, data) { 
      const result = await request(this.cache,
        query(gql`
        mutation login($url: String!, $username: String!, $password: String!) {
          session(url: $url) {
            mediaBankLogin(username: $username, password: $password) {
              message
              success
            }
          }
        }
      `),
        {
          ...command,
        },
      );

      return result.session.mediaBankLogin;
    },
    'litium',
  ),
  logout: defineReceiver(
    commands.logout,
    async function (command, data) { 
      const result = await request(this.cache,
        query(
        gql`
        mutation logout($url: String!) {
          session(url: $url) {
            mediaBankLogout
          }
        }
      `),
        {
          ...command,
        },
      );

      return { result: result.session.mediaBankLogout };
    },
    'litium',
  ),
};
