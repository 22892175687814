import { computed, inject, ref } from 'vue';
import {
  pageKey, routeKey, setMeta, Meta,
} from '@drapejs/core';
import { channelKey } from '@/keys';
import { toLitiumMediaUrlKey } from '@distancify/drapejs-litium/lib/keys';

export default function (middleware?: (meta: Meta) => Meta) {
  const route = inject(routeKey, <any>{});
  const page = inject(pageKey, ref());
  const channel = inject(channelKey, ref(<any>{}));
  const toLitiumMediaUrl = inject(toLitiumMediaUrlKey, (url: string, opts: any) => '');
  const favicon = computed(() => channel.value?.website?.favIconImageId);

  setMeta(() => {

    let title = page.value.title || page.value.fields?._name;

    if (title?.toLowerCase().indexOf('didriksons') === -1) {
      title = `${title} - Didriksons`;
    } 

    const result = {
      title,
      // lang: getChannelConfiguration(route)?.language?.substr(0, 2),
      props: {
        description: page.value.description,
        // 'og:image': page.value.metaImage ? imageResize(page.value.metaImage, { width: 1200, height: 630 })?.filename : null,
      },
      links: <[{
        rel: string,
        type?: string,
        href: string,
      }]>[
          {
            rel: 'canonical',
            href: `${route.protocol}//${route.host}${route.pathname?.replace(/\/+$/, '')}`,
          },
        ],
    };

    if (favicon.value) {
      result.links.push({
        rel: 'shortcut icon',
        type: 'image/png',
        href: toLitiumMediaUrl(favicon.value, { fillArea: { width: 256, height: 256 } }),
      });
    }

    return middleware ? middleware(result) : result;
  });
}
